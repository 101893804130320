/* Your styles goes here. */
.appContainer {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

th {
  text-align: center;
  padding: 8px;
  font-size: 25px;
  flex: 1;
}
td {
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 25px;
  flex: 1;
  background-color: transparent;
}

tr {
  display: flex;
}

table {
  width: 100%;
  height: 200px;
  border-collapse: collapse;
}

.textLeft {
  text-align: left;
}

.textBold {
  font-weight: bold;
}

.detailView {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  margin-right: 4px;
}

.jobInfo {
  border-radius: 5px;
  border: 2px solid #20367e;
  flex: 1;
  padding: 10px;
  margin-top: 10px;
}

.dropDown {
  margin-left: 15px;
  border-radius: 15px !important;
}

.unitDropDownItem {
  border-radius: 15px;
}

.arrowStyle {
  margin-top: 4px;
}

.dropDownClass {
  border-radius: 15px !important;
  width: 180px;
}

.listButton {
  height: 30px;
  width: 60px;
  border-radius: 5px;
  margin: 0px;
  transition: all 0.3s;
  padding: 0px;
  border: 1px solid #20367e;
  background-color: white;
  color: rgb(24, 21, 21);
  font-weight: normal;
  font-size: 15px;
}

.markAsCompleted {
  border-radius: 5px;
  padding: 10px;
  margin-left: 30px;
  margin-right: 20px;
  background-color: black;
  border-width: 0px;
  color: white;
}

.checkList {
  padding-top: 10px;
}

.checkBox {
  box-sizing: border-box;
  padding: 0;
  accent-color: black;
}

.logoutButton {
  margin: 0px;
  position: absolute;
  right: 20px;
  top: 50px;
  border-width: 1px;
  width: 220px;
  height: 40px;
  padding: 30px 8px 8px 30px;
  border-radius: 20px;
  border: 1px;
  background-color: #f8fafc;
  border-color: #e8e8e8;
  color: #252a32;
  font-family: "Roobert";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  box-shadow: 0px 10px 15px -3px #12121714;
  cursor: pointer;
}

.headerText {
  display: flex;
  margin-bottom: 20px;
}
.headerBody {
  display: flex;
  flex-direction: column;
}
.headerBodyInfo {
  justify-content: space-between;
}
.headerIcon {
  width: 15px;
  height: 15px;
}
.headerView {
  display: flex;
  flex-direction: row;
}
.headerColumnView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f5f7;
  padding: 10px;
}
.headertext {
  font-size: 12px;
  text-align: center;
}
.checkbox {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.headerCheckbox {
  display: flex;
  flex-direction: row;
}

.accordionHeaderView {
  display: flex;
  flex-direction: column;
}

.accordionHeaderColumnView {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.accordionHeader {
  background-color: #f3f5f7;
}

.detailsHeader {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
  text-align: left;
  font-family: "Roobert";
}

.hideHeader {
  margin-top: 20px;
}

.detailsBodyText {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6b7583;
  text-align: left;
  display: flex;
  flex: 1;
  font-family: "Roobert";
}
.detailsBodyTextVlaue {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #141923;
  text-align: left;
  display: flex;
  flex: 1;
  font-family: "Roobert";
}

.detailsBodyView {
  display: flex;
  flex-direction: row;
}

.detailsBodyViewBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.detailsBodyViewInfo {
  display: flex;
  flex-direction: column;
}
.detailsInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
}

.statusButtonText {
  background-color: #ebfeeb;
  border-radius: 4px;
  padding: 5px;
  margin-right: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 65px;
  color: #058034;
}

.buttonView {
  border-radius: 20px;
  width: 102px;
  height: 40px;
  padding: 8px;
  background-color: #062dd7;
  color: white;
  margin-right: 10px;
  border-width: 0px;
  cursor: pointer;
}

.detailsBottomView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 200px;
}

.fillRemainingSpace {
  display: flex;
  flex: 1;
}

.detailsBottomInfo {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #062dd7;
}

.parentTable
  table
  tbody
  .ant-table-expanded-row.ant-table-expanded-row-level-1
  > td {
  padding: 0px !important;
  margin: 0%;
}

.nestedTable > div > div > div {
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.ant-table-thead th {
  font-size: 14px; /* Change this value to adjust the font size */
}

.ant-table-tbody td {
  font-size: 14px;
}

.ant-table-content table {
  margin-right: 0px;
}

.ant-table-expand-icon-col {
  display: none;
}

.tableView {
  align-items: "center";
  flex: 1;
  margin-bottom: 20px;
  margin-top: 20px;
  width: "100%";
  box-shadow: 0px 1px 2px 0px #1212170d;
  border-width: 1px;
  border-color: #d0d5db;
  margin-left: 70px;
  margin-right: 70px;
}

.detailsView {
  display: "flex";
  flex-direction: "column";
  height: "100%";
  width: "100%";
}

.tableHeaderView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 80px;
  margin-right: 80px;
}

.tableHeaderSubView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tableExpandleView {
  align-items: "center";
  margin-bottom: "20px";
  margin-top: "20px";
  width: "100%";
}

.tableFooterView {
  align-items: "center";
  display: "flex";
  justify-content: "space-between";
  background-color: #058034;
  flex: 1;
}

.photoGrid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 10px;
}

.imageView {
  width: 243px;
  height: 185px;
  border-radius: 20px;
}

.container {
  display: flex;
  justify-content: center;
}

.imageTitle {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
  width: 150px;
  margin-top: 10px;
  margin-left: 10px;
  font-family: "Roobert";
}

.headerTitle {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 24px;
  margin-left: 80px;
  margin-top: 20px;
}

.headerBar {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerBarText {
  color: #141923;
  font-size: 14px;
  font-weight: 600;
  font-family: "Roobert";
}

.logo {
  width: 80px;
  height: 32px;
  margin-right: 16px;
}

.tableInfo {
  margin: 0px;
}

body {
  font-family: "Roobert";
  margin: 0px;
  padding: 0px;
}

.headerTitleText {
  color: #6b7583;
  font-family: "Roobert";
  font-size: 12;
  font-weight: 600;
}

.headerTitleTextHighlighted {
  color: #062dd7;
  font-family: "Roobert";
  font-size: 12;
  font-weight: 600;
  background-color: white;
}

.headerRighIcon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #002eff;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  font-family: "Roobert";
}

.appBarInfo {
  display: flex;
  flex-direction: row;
}

.headerViewInfo {
  display: flex;
}

.searchStyle {
  border-radius: 10px;
  height: 45px;
  width: 350px;
}

.filterStyle {
  align-items: center;
  display: flex;
  margin-left: 16px;
}

.filterStyleInfo {
  color: #6b7583;
  font-size: 16px;
  margin-right: 12px;
}

.footerStyle {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.arrowImageStyle {
  height: 6px;
  width: 12px;
}

.paginationStyle {
  flex: 1;
  text-align: center;
}

.arrowLeftOutlinedStyle {
  margin-right: 8px;
}

.arrowRightOutlinedViewStyle {
  text-align: right;
}

.arrowRightOutlinedStyle {
  margin-left: 8px;
}

.spinner {
  align-self: center;
  margin-left: "20%";
}

.imageData {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoutPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  font-family: "Roobert";
}

.popupContent {
  background-color: white;
  padding: 0px 20px 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 10000;
  font-family: "Roobert";
}

.buttonContainer {
  margin-top: 20px;
  font-family: "Roobert";
}

.cancelButton {
  background-color: #ddd;
  border: none;
  padding: 8px 16px;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Roobert";

  &:hover {
    opacity: 0.8;
  }
}

.logoutOkButton {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 16px;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Roobert";

  &:hover {
    opacity: 0.8;
  }
}

.loading {
  top: 20;
}

.sortLogo {
  width: 18px;
  height: 18px;
}

.statusLabel {
  color: #6b7583;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 28px;
  font-family: "Roobert";
}

.imageLoadView {
  width: 60vw;
  height: 75vh;
}

.modalTitle {
  margin: 0px 0px 16px 16px;
  font-family: "Roobert";
  font-size: 18px;
  font-weight: 600;
  color: #141923;
}

.modalFooter {
  margin: 0px 0px 0px 16px;
  font-family: "Roobert";
  font-size: 16px;
  font-weight: 500;
  color: #141923;
}

.tableRow {
  font-weight: bold;
}

.seperator {
  align-items: center;
  margin: 0px 24px;
  height: 2px;
  background-color: #e3e8ef;
}

.imageHeader {
  display: flex;
  margin-left: 24px;
}

.storeDetailsText {
  font-family: "Roobert";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #141923;
}

.imageViewerModal {
  background-color: white;
}

.sectionFieldInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sectionData {
  flex-direction: column;
  width: 40%;
}

.leftText {
  text-align: left;
  display: flex;
  width: 250px;
  margin-right: 10px;
}

.rightText {
  text-align: left;
  width: 300px;
}

.sectionHeader {
  display: flex;
}

.mainSection {
  margin-left: 24px;
}

.photoInfo {
  margin-right: 10px;
}
