/* You can add global styles to this file, and also import other style files */
.parentTable
  table
  tbody
  .ant-table-expanded-row.ant-table-expanded-row-level-1
  > td {
  padding: 0px !important;
}

.nestedTable > div > div > div {
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.ant-table-thead th {
  font-size: 14px;
  font-family: "Roobert";
}

.ant-table-tbody td {
  font-size: 14px;
  font-family: "Roobert";
}

.ant-table-expand-icon-col {
  display: none;
}

@font-face {
  font-family: "Roobert";
  src:
    local("Roobert"),
    url(./fonts/RoobertRegular.ttf) format("truetype");
}

@font-face {
  font-family: "Roobert";
  font-weight: 900;
  src:
    local("Roobert"),
    url(./fonts/RoobertBold.ttf) format("truetype");
}

@font-face {
  font-family: "Roobert";
  font-weight: 500;
  src:
    local("Roobert"),
    url(./fonts/RoobertSemiBold.ttf) format("truetype");
}

.ant-table-wrapper .ant-pagination-prev,
.ant-table-wrapper .ant-pagination-next {
  display: none !important;
}

.float-label {
  position: relative;
  margin-bottom: 12px;
  font-family: "Roobert";
}

.label {
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 12px;
  transition: 0.2s ease all;
  font-family: "Roobert";
}

.label-float {
  top: 6px;
  font-size: 10px;
  font-family: "Roobert";
}

.ant-select-custom .ant-select-selector {
  width: 201px !important;
  height: 45px !important;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.ant-select-custom .ant-select-selection-item {
  font-size: 14px; /* Custom font size for selected item */
  margin-top: 10px;
  font-family: "Roobert";
}

.ant-select-dropdown .ant-select-item-option {
  font-size: 14px; /* Set your desired font size */
  font-family: "Roobert";
}

.ant-select-custom .ant-select-arrow {
  color: #6b7583; /* Custom arrow color */
  margin-top: 3px;
  width: 12px;
  height: 6px;
}

$enabled-color: #062dd7;
$disabled-color: #e3e8ef;
$enabled-text-color: white;
$disabled-text-color: #97a3b4;
$button-padding: 8px;
$button-border-radius: 20px;
$button-border-width: 0px;
$download-button-border-width: 1.5px;

// Mixin for button styles
@mixin button-styles($color, $textColor, $borderWidth, $borderColor) {
  padding: $button-padding;
  border-radius: $button-border-radius;
  color: $textColor;
  background-color: $color;
  width: 102px;
  height: 40px;
  margin-right: 10px;
  border-width: $borderWidth;
  border-color: $borderColor;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: "Roobert";
  font-weight: 500;
  &:hover {
    background-color: darken($color, 10%);
  }

  &:active {
    background-color: lighten($color, 10%);
  }
}

.buttonView {
  &.enabled {
    @include button-styles(
      $enabled-color,
      $enabled-text-color,
      $button-border-width,
      $enabled-color
    );
  }

  &.downloadenabled {
    @include button-styles(
      $enabled-text-color,
      $enabled-color,
      $download-button-border-width,
      $enabled-color
    );
  }

  &.downloaddisabled {
    @include button-styles(
      $enabled-text-color,
      $disabled-text-color,
      $button-border-width,
      $enabled-text-color
    );
    cursor: not-allowed;
    opacity: 0.6;

    &:hover {
      background-color: $enabled-text-color;
    }

    &:active {
      background-color: $enabled-text-color;
    }
  }

  &.disabled {
    @include button-styles(
      $disabled-color,
      $disabled-text-color,
      $button-border-width,
      $disabled-color
    );
    cursor: not-allowed;
    opacity: 0.6;

    &:hover {
      background-color: $disabled-color;
    }

    &:active {
      background-color: $disabled-color;
    }
  }
}

.custom-table .ant-table-tbody > tr > td {
  font-size: 16px; /* Set your desired font size */
  font-family: "Roobert";
}

.fullscreen-modal .ant-modal {
  width: 100vw;
  max-width: 100vw;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreen-modal .ant-modal-content {
  height: 90vh;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreen-modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.loading-view {
  display: flex;
  align-content: center;
  vertical-align: middle;
}

.loading-text {
  font-size: "18px";
  font-weight: "bold";
}

.custom-modal .ant-modal {
  width: auto;
  height: auto;
  background-color: white;
  overflow: hidden;
}

.custom-modal .ant-modal-content {
  height: auto;
  width: auto;
  background-color: white;
  max-width: 400px;
  max-width: 400px;
  overflow: hidden;
}

.approval-modal-title {
  font-size: 20px;
  font-family: "Roobert";
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 4px;
  margin-top: 25px;
}

.approval-modal-message {
  font-size: 16px;
  font-family: "Roobert";
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 16px;
}

.approval-modal-footer {
  align-items: center;
  flex-direction: column;
  display: flex;
}

.approval-modal-button {
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 28px;
  color: $enabled-text-color;
  background-color: $enabled-color;
  width: 360px;
  height: 56px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: "Roobert";
  font-weight: 500;
  border: none;
  &:hover {
    background-color: darken($enabled-color, 10%);
  }

  &:active {
    background-color: lighten($enabled-color, 10%);
  }
  &.cancel {
    background-color: #e3e8ef;
    color: #141923;
  }
}

.approval-modal-view {
  background-color: white;
}

.approval-modal-footer-view-title {
  font-size: 16px;
  font-family: "Roobert";
  font-weight: normal;
  line-height: 24px;
  display: flex;
}

.approval-modal-body-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f3f5f7;
  padding: 16px;
  margin-bottom: 30px;
  width: Fixed (361px) px;
  height: Hug (72px) px;
  gap: var(--spacingspacing-none);
  border-radius: 12px 12px 12px 12px;
}

.approval-modal-switch {
  transform: scale(1.5);
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 10px;
}

.ant-switch-checked {
  background: #002eff !important;
}

.info-icon {
  width: 24px;
  height: 24px;
}

.approval-modal-tooltip {
  font-size: 12px;
  font-family: "Roobert";
  font-weight: 400;
  line-height: 26px;
  color: #141923;
  text-align: left;
}

.approval-modal-tooltip-view {
  transform: translateX(1415%) !important;
  position: absolute;
  top: 10px;
}

.custom-close-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  color: #000;
  font-size: 16px;
  cursor: pointer;
  z-index: 1000;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  width: 55%;
  margin-left: 24px;
}

.image-container img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
}
